import { RouteRecordRaw } from "vue-router";

const guangdong: RouteRecordRaw[] = [
  {
    path: "/guangdong",
    children: [
      {
        path: "",
        component: () => import("@/views/locals/guangdong/home/index.vue"),
      },
      {
        path: "dzbd",
        component: () => import("@/views/locals/guangdong/dzbd/index.vue"),
      },
      {
        path: "wytj",
        component: () => import("@/views/locals/guangdong/wytj/index.vue"),
      },
      {
        path: "dzswj",
        component: () => import("@/views/locals/guangdong/dzswj/index.vue"),
      },
      {
        path: "wyzx",
        component: () => import("@/views/locals/guangdong/wyzx/index.vue"),
      },
      {
        path: "bsdh",
        component: () => import("@/views/locals/guangdong/bsdh/index.vue"),
      },
      // {
      //     path: "bssc",
      //     component: () => import("@/views/locals/guangdong/bssc/YZZ.vue"),
      // },
      {
        path: "wyjsb",
        component: () => import("@/views/locals/guangdong/wyjsb/index.vue"),
      },
      {
        path: "sscx",
        component: () => import("@/views/locals/guangdong/sscx/index.vue"),
      },
      {
        path: "sbgn",
        component: () => import("@/views/locals/guangdong/sbgn/index.vue"),
      },
      {
        path: "yuezhizhu",
        component: () => import("@/views/locals/guangdong/components/YZZ.vue"),
      },
      {
        path: "subWind",
        component: () => import("@/views/locals/guangdong/subWind/index.vue"),
      },
    ],
  },
];

export default guangdong;
