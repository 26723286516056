import {RouteRecordRaw} from "vue-router";

const hainan: RouteRecordRaw[] = [
    {
        path: "/hainan",
        children: [
            {
                path: "",
                component: () => import("@/views/locals/hainan/home/index.vue"),
            },
            {
                path: "dzbd",
                component: () => import("@/views/locals/hainan/dzbd/index.vue"),
            },
            {
                path: "wytj",
                component: () => import("@/views/locals/hainan/wytj/index.vue"),
            },
            {
                path: "dzswj",
                component: () => import("@/views/locals/hainan/dzswj/index.vue"),
            },
            {
                path: "wyzx",
                component: () => import("@/views/locals/hainan/wyzx/index.vue"),
            },
            {
                path: "bsdh",
                component: () => import("@/views/locals/hainan/bsdh/index.vue"),
            },
            // {
            //     path: "bssc",
            //     component: () => import("@/views/locals/hainan/bssc/YZZ.vue"),
            // },
            {
                path: "wyjsb",
                component: () => import("@/views/locals/hainan/wyjsb/index.vue"),
            },
        ],
    },
]

export default hainan
