import {defineStore} from "pinia";
import http from "@/service/http";
import piniaPersistConfig from "@/store/piniaPersist";

export const useConfigStore = defineStore({
    id: "config",
    state: () => ({
        questionnaire: {} as any,
        deviceId: null,
        questionnaireId: null,
        videoGroupId: null,
        status: null,
        clientVersion: null,
        isQuestionnaire: false,
        clothPoint: '',
        localCustom:{},
        deviceCode:'',
        yzzId:null,
        isTimeout:false,
        timeoutDate:null,
        dzswjUrl: null,
    }),
    actions: {
        async getConfig() {
            const deviceNo = window.localStorage.getItem('DEVICE_NO');
            let {data}: any = await http.post("/data/device/getDeviceConfig", {deviceNo: deviceNo});

            Object.assign(this,data)
            this.localCustom = data.localCustom || {}
        },
        getCustomCode() {
            return this.localCustom.code;
        },
    },
    persist: piniaPersistConfig('config')
});


