import {RouteRecordRaw} from "vue-router";

const jilin: RouteRecordRaw[] = [
    {
        path: "/jilin",
        children: [
            {
                path: "",
                component: () => import("@/views/locals/jilin/home/index.vue"),
            },
            {
                path: "dzbd",
                component: () => import("@/views/locals/jilin/dzbd/index.vue"),
            },
            {
                path: "wytj",
                component: () => import("@/views/locals/jilin/wytj/index.vue"),
            },
            {
                path: "dzswj",
                component: () => import("@/views/locals/jilin/dzswj/index.vue"),
            },
            {
                path: "wyzx",
                component: () => import("@/views/locals/jilin/wyzx/index.vue"),
            },
            {
                path: "bsdh",
                component: () => import("@/views/locals/jilin/bsdh/index.vue"),
            },
            // {
            //     path: "bssc",
            //     component: () => import("@/views/locals/jilin/bssc/YZZ.vue"),
            // },
            {
                path: "wyjsb",
                component: () => import("@/views/locals/jilin/wyjsb/index.vue"),
            },
        ],
    },
]

export default jilin
