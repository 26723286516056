import {RouteRecordRaw} from "vue-router";

const shanxi: RouteRecordRaw[] = [
    {
        path: "/shanxi",
        children: [
            {
                path: "",
                component: () => import("@/views/locals/shanxi/home/index.vue"),
            },
            {
                path: "dzbd",
                component: () => import("@/views/locals/shanxi/dzbd/index.vue"),
            },
            {
                path: "wytj",
                component: () => import("@/views/locals/shanxi/wytj/index.vue"),
            },
            {
                path: "dzswj",
                component: () => import("@/views/locals/shanxi/dzswj/index.vue"),
            },
            {
                path: "wyzx",
                component: () => import("@/views/locals/shanxi/wyzx/index.vue"),
            },
            {
                path: "bsdh",
                component: () => import("@/views/locals/shanxi/bsdh/index.vue"),
            },
            // {
            //     path: "bssc",
            //     component: () => import("@/views/locals/shanxi/bssc/YZZ.vue"),
            // },
            {
                path: "wyjsb",
                component: () => import("@/views/locals/shanxi/wyjsb/index.vue"),
            },
        ],
    },
]

export default shanxi
