import { RouteRecordRaw } from "vue-router";

const gansu: RouteRecordRaw[] = [
  {
    path: "/gansu",
    children: [
      {
        path: "",
        component: () => import("@/views/locals/gansu/home/index.vue"),
      },
      {
        path: "dzbd",
        component: () => import("@/views/locals/gansu/dzbd/index.vue"),
      },
      {
        path: "wytj",
        component: () => import("@/views/locals/gansu/wytj/index.vue"),
      },
      {
        path: "dzswj",
        component: () => import("@/views/locals/gansu/dzswj/index.vue"),
      },
      {
        path: "wyzx",
        component: () => import("@/views/locals/gansu/wyzx/index.vue"),
      },
      {
        path: "bsdh",
        component: () => import("@/views/locals/gansu/bsdh/index.vue"),
      },
      // {
      //     path: "bssc",
      //     component: () => import("@/views/locals/gansu/bssc/YZZ.vue"),
      // },
      {
        path: "wyjsb",
        component: () => import("@/views/locals/gansu/wyjsb/index.vue"),
      },
    ],
  },
];

export default gansu;
