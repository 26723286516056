import { computed, ref } from "vue";

export const useLocal = () => {
  const local = ref(window.localStorage.getItem("DEVICE_LOCAL"));

  const isShenzhen = computed(() => {
    return local.value == "shenzhen";
  });

  // 是否是宁夏
  const isNx = computed(() => {
    return local.value == "ningxia";
  });

  // 是不是吉林
  const isJl = computed(() => {
    return local.value == "jilin";
  });

  const isShanxi = computed(() => {
    return local.value == "shanxi";
  });

  const isGuangdong = computed(() => {
    return local.value == "guangdong";
  });

  const isGansu = computed(() => {
    return local.value == "gansu";
  });

  const isHainan = computed(() => {
    return local.value == "hainan";
  });

  return {
    local,
    isShenzhen,
    isNx,
    isJl,
    isShanxi,
    isGuangdong,
    isGansu,
    isHainan,
  };
};
