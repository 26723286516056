import {RouteRecordRaw} from "vue-router";

const shenzhen: RouteRecordRaw[] = [
    {
        path: "/shenzhen",
        children: [
            {
                path: "",
                component: () => import("@/views/locals/shenzhen/home/index.vue"),
            },
            {
                path: "dzbd",
                component: () => import("@/views/locals/shenzhen/dzbd/index.vue"),
            },
            {
                path: "wytj",
                component: () => import("@/views/locals/shenzhen/wytj/index.vue"),
            },
            {
                path: "dzswj",
                component: () => import("@/views/locals/shenzhen/dzswj/index.vue"),
            },
            {
                path: "wyzx",
                component: () => import("@/views/locals/shenzhen/wyzx/index.vue"),
            },
            {
                path: "bsdh",
                component: () => import("@/views/locals/shenzhen/bsdh/index.vue"),
            },
            {
                path: "bssc",
                component: () => import("@/views/locals/shenzhen/bssc/index.vue"),
            },
        ],
    },
]

export default shenzhen