import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import shenzhen from "@/router/shenzhen";
import jilin from "./jilin";
import ningxia from "./ningxia";
import shanxi from "./shanxi";
import guangdong from "./guangdong";
import hainan from "@/router/hainan";
import gansu from "@/router/gansu";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/index/index.vue"),
  },
  // 表单系统
  {
    path: "/dztd",
    name: "DztdIndex",
    component: () => import("@/views/dztd/newIndex.vue"),
  },
  {
    path: "/selectPage",
    name: "SelectPage",
    component: () => import("@/views/dztd/SelectPage.vue"),
  },
  {
    path: "/dztd/detail",
    name: "DztdDetail",
    component: () => import("@/views/dztd/newDetail.vue"),
  },
  // 税务系统
  {
    path: "/taxAdi",
    name: "TaxAdiIndex",
    component: () => import("@/views/taxAdi/Index.vue"),
  },
  // 税务系统
  {
    path: "/taxAdiList",
    name: "TaxAdiList",
    component: () => import("@/views/taxAdi/newList.vue"),
  },
  // 常用软件
  {
    path: "/commonSoftware",
    name: "CommonSoftware",
    component: () => import("@/views/commonSoftware/index.vue"),
  },
  // 常用网站
  {
    path: "/commonWebsite",
    name: "CommonWebsite",
    component: () => import("@/views/commonWebsite/index.vue"),
  },
  // 常用网站
  {
    path: "/commonWebsite",
    name: "CommonWebsite",
    component: () => import("@/views/commonWebsite/index.vue"),
  },
  {
    path: "/commonWebsite",
    name: "CommonWebsite",
    component: () => import("@/views/commonWebsite/index.vue"),
  },
  {
    path: "/screencastLinkage",
    name: "ScreencastLinkage",
    component: () => import("@/views/taxAdi/screencastLinkage.vue"),
  },
  {
    path: "/fdzm",
    name: "FdzmIndex",
    component: () => import("@/views/fdzm/Index.vue"),
  },
  {
    path: "/znkf",
    name: "ZnkfIndex",
    component: () => import("@/views/znkf/Index.vue"),
  },
  {
    path: "/tjbg",
    name: "tjbg",
    component: () => import("@/views/tjbg/Index.vue"),
  },
  {
    path: "/ycxb",
    name: "YcxbIndex",
    component: () => import("@/views/ycxb/YcxbIndex.vue"),
  },
  {
    path: "/talk",
    name: "talk",
    // component: () => import("@/views/talk/index.vue"),
    component: () => import("@/views/talk/newIndex.vue"),
  },
  {
    path: "/talk-old",
    name: "talk-old",
    component: () => import("@/views/talk/index.vue"),
  },
  {
    path: "/socialSecurityFunc",
    name: "socialSecurityFunc",
    component: () => import("@/views/socialSecurityFunc/index.vue"),
  },
  {
    path: "/nonactivated",
    component: () => import("@/views/nonactivated/index.vue"),
  },
  ...shenzhen,
  ...jilin,
  ...ningxia,
  ...shanxi,
  ...guangdong,
  ...hainan,
  ...gansu,
];

console.log(routes);
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
