import { RouteRecordRaw } from "vue-router";

const ningxia: RouteRecordRaw[] = [
  {
    path: "/ningxia",
    children: [
      {
        path: "",
        component: () => import("@/views/locals/ningxia/home/index.vue"),
      },
      {
        path: "dzbd",
        component: () => import("@/views/locals/ningxia/dzbd/index.vue"),
      },
      {
        path: "wytj",
        component: () => import("@/views/locals/ningxia/wytj/index.vue"),
      },
      {
        path: "dzswj",
        component: () => import("@/views/locals/ningxia/dzswj/index.vue"),
      },
      {
        path: "wyzx",
        component: () => import("@/views/locals/ningxia/wyzx/index.vue"),
      },
      {
        path: "bsdh",
        component: () => import("@/views/locals/ningxia/bsdh/index.vue"),
      },
      // {
      //     path: "bssc",
      //     component: () => import("@/views/locals/ningxia/bssc/YZZ.vue"),
      // },
      {
        path: "wyjsb",
        component: () => import("@/views/locals/ningxia/wyjsb/index.vue"),
      },
      {
        path: "wszm",
        component: () => import("@/views/locals/ningxia/wszm/index.vue"),
      },
    ],
  },
];

export default ningxia;
