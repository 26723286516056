<template>
  <RouterView />
</template>

<script setup>
import { useConfigStore } from "@/store/config";
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

const configStore = useConfigStore();
const { VITE_CLIENT_VERSION } = import.meta.env;

const { push } = useRouter();

const checkClientVersion = () => {
  if (VITE_CLIENT_VERSION != configStore.clientVersion) {
    // 记录已经刷新过了，防止一直刷新
    var localVersion = localStorage.getItem("refreshVersion");
    if (localVersion == configStore.clientVersion) {
      return;
    }
    localStorage.setItem("refreshVersion", configStore.clientVersion);
    location.reload();
  }
};

onMounted(async () => {
  await configStore.getConfig();
  checkClientVersion();
  if (configStore.status == 3) {
    if (configStore.isTimeout) {
      push({ path: "/nonactivated" });
    }
  } else if (configStore.status != 1) {
    push({ path: "/nonactivated" });
  }
});
</script>

<style>
body {
}

* {
  margin: 0;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-jc-spb {
  justify-content: space-between;
}

.flex-ai-c {
  align-items: center;
}

#local div {
  z-index: 999;
  background-color: unset;
}
</style>
